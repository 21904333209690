import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { IPhoneModels } from "../../assets/IPhoneModels";
import useWindowSize from "../../common/hooks/useWindowSize";

const CommonLayout = ({ children, isMap, className, p = "2" }) => {
  const { width } = useWindowSize();

  const platform = useSelector((state) => state.drivers.platform, shallowEqual);
  const modelName = useSelector(
    (state) => state.drivers.modelName,
    shallowEqual
  );

  const styles = isMap
    ? "p-0 w-full h-full"
    : platform === "ios"
    ? IPhoneModels.includes(modelName) ||
      modelName.toLowerCase().includes("ipad")
      ? "pt-8 p-3"
      : width > 600 && !modelName.toLowerCase().includes("ipad")
      ? "px-[2em] p-3"
      : "pt-16 p-3"
    : "p-2";

  return (
    <div
      className={`${styles} ${className} overflow-y-auto overflow-x-hidden w-full`}
    >
      {children}
    </div>
  );
};

export default CommonLayout;
