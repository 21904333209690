import {
  Close,
  Insights,
  InsightsOutlined,
  LocalGasStationRounded,
  Map,
} from "@mui/icons-material";
import { Badge, IconButton } from "@mui/material";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CurrentDayFleetSummaryCards from "../../DataLoaders/CurrentDayFleetSummaryCards";
import {
  Dasboard,
  DasboardActive,
  Home,
  HomeActive,
  Notifications,
  NotificationsActive,
  Settings,
  SettingsActive,
} from "../../assets/svgs";
import { devicesActions, selectorActions } from "../../store";
import FeatureFlags from "../FeatureFlags/FeatureFlags";

const BottomMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    devices: { selectedId: selectedDeviceId },
    events: { items: events, readId },
    session: { user },
    subscriptions: { plans, items: subscriptions },
  } = useSelector((state) => state, shallowEqual);

  const eventsCount = Object.keys(events).length;
  const readCount = Object.keys(readId).length;
  const unread = eventsCount - readCount;
  const subscriptionsActive = true;

  let advancedAnalysis = user?.administrator === true;
  let hasFuel = user && user.administrator === true;

  const [isFleetOpen, setIsFleetOpen] = useState(false);

  if (!advancedAnalysis || !hasFuel) {
    Object.keys(subscriptions).forEach((subsId) => {
      if (!advancedAnalysis) {
        advancedAnalysis = FeatureFlags.includes(
          "advanced_fuel_analysis",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }
      if (!hasFuel) {
        hasFuel = FeatureFlags.includes(
          "fuel_events",
          plans[subscriptions[subsId].planid]?.attributes?.features
        );
      }
    });
  }

  const handleNavigation = (path, action = () => {}) => {
    navigate(path);
    action();
  };

  const renderIcon = (path, ActiveIcon, InactiveIcon) =>
    location.pathname.includes(path) ? ActiveIcon : InactiveIcon;

  return (
    <div className="lg:hidden z-[9999] relative px-5 pt-2 pb-5 flex justify-between items-center w-full bg-white shadow-[0px_-10px_10px_-5px_#00000040]">
      <div
        className="cursor-pointer homeStep-1"
        data-testid="handleHome"
        onClick={() =>
          handleNavigation("/", () => dispatch(devicesActions.select(null)))
        }
      >
        {location.pathname === "/" ? HomeActive : Home}
      </div>

      {(advancedAnalysis || hasFuel) && (
        <Link to="/map">
          <div
            onClick={() => {
              if (subscriptionsActive) {
                dispatch(devicesActions.select(null));
              }
            }}
          >
            <Map
              sx={{
                color: location.pathname === "/map" ? "#F07023" : "black",
              }}
            />
          </div>
        </Link>
      )}

      {/* {advancedAnalysis && (
        <>
          <div onClick={() => setIsFleetOpen(true)}>
            <LocalGasStationRounded
              sx={{ color: isFleetOpen ? "#F07023" : "black" }}
            />
          </div>

          {isFleetOpen && (
            <div
              className="absolute w-full left-0 right-0 bottom-full bg-white shadow-md
        overflow-hidden transition-all duration-300 ease-in-out h-[80vh] overflow-y-auto"
            >
              <IconButton
                sx={{
                  zIndex: 999999,
                  marginLeft: "90%",
                }}
                onClick={() => setIsFleetOpen(false)}
              >
                <Close />
              </IconButton>

              <CurrentDayFleetSummaryCards />
            </div>
          )}
        </>
      )} */}

      <div
        className="cursor-pointer lg:my-12 step-14"
        data-testid="handleDasboard"
        onClick={() => {
          const path = advancedAnalysis
            ? "/reports/fleetSummary"
            : hasFuel
            ? `/reports/basicFuel/${
                selectedDeviceId ? selectedDeviceId : "null"
              }`
            : `/reports/events/${selectedDeviceId ? selectedDeviceId : "null"}`;
          handleNavigation(path);
        }}
      >
        {renderIcon("/reports", DasboardActive, Dasboard)}
      </div>

      {subscriptionsActive && advancedAnalysis && (
        <div
          className="cursor-pointer step-15"
          onClick={() =>
            handleNavigation(
              "/advancedAnalytics/dailyFuelAnalytics/null",
              () => {
                dispatch(selectorActions.resetReportState({}));
              }
            )
          }
        >
          {renderIcon(
            "/advancedAnalytics",
            <Insights sx={{ color: "#F07023" }} />,
            <InsightsOutlined />
          )}
        </div>
      )}

      <div
        className="cursor-pointer relative step-16"
        data-testid="handleNotifications"
        onClick={() => handleNavigation("/notifications")}
      >
        <Badge badgeContent={unread} color="error">
          {renderIcon("/notifications", NotificationsActive, Notifications)}
        </Badge>
      </div>

      <div
        data-testid="handleAccount"
        className="cursor-pointer step-17"
        onClick={() => handleNavigation("/settings/my_account")}
      >
        {renderIcon("/settings", SettingsActive, Settings)}
      </div>
    </div>
  );
};

export default BottomMenu;
