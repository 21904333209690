import React from "react";
import CommonLayout from "../../../layout/Components/CommonLayout";
import SettingsMenu from "./SettingsMenu";
import SettingsSidebar from "./SettingsSidebar";

const SettingsLayout = ({ children }) => {
  return (
    <div className="flex h-full">
      <aside className="w-80 h-full p-2 border-r hidden lg:block">
        <SettingsMenu />
      </aside>
      <main className="flex flex-col flex-1 overflow-auto">
        <CommonLayout className="bg-gray-100 lg:hidden">
          <SettingsSidebar />
        </CommonLayout>
        <div className="flex-1 overflow-auto p-2">{children}</div>
      </main>
    </div>
  );
};

export default SettingsLayout;
